import React, { useEffect } from "react";

export default function Policy() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);  return (
    <div
      name="termly-embed"
      data-id="e1d37665-af4e-4258-b0f4-a9d06a1ae24b"
      data-type="iframe"
    ></div>
  );
}
