import React, { useState, useEffect, useRef } from 'react';
import StripePricingTable from '../components/plans/StripePricingTable';
import PreSaleImage from '../assets/images/pre_sale_banner-3.png';
import './PreSaleOffer.css';

const PreSaleOffer = () => {
  const [countdown, setCountdown] = useState(0);
  const [discountPercentage1, setDiscountPercentage1] = useState(30);
  const [discountPercentage2, setDiscountPercentage2] = useState(50);
  const [activeDiscount1, setActiveDiscount1] = useState(0);
  const [activeDiscount2, setActiveDiscount2] = useState(0);
  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const stripePricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
  const pricingSectionRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const target = new Date('2025-01-01').getTime();
      const diff = target - now;
      const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
      setCountdown(weeks);

      const currentDate = new Date();
      const lastWeekOfOctober = new Date(currentDate.getFullYear(), 9, 28);
      const lastWeekOfDecember = new Date(currentDate.getFullYear(), 11, 28);

      if (currentDate >= lastWeekOfOctober && currentDate < new Date(lastWeekOfOctober.getFullYear(), lastWeekOfOctober.getMonth(), lastWeekOfOctober.getDate() + 21)) {
        setDiscountPercentage1(30);
        setDiscountPercentage2(50);
        setActiveDiscount1(0);
        setActiveDiscount2(0);
      } else if (currentDate >= new Date(lastWeekOfOctober.getFullYear(), lastWeekOfOctober.getMonth(), lastWeekOfOctober.getDate() + 21) && currentDate < new Date(lastWeekOfDecember.getFullYear(), lastWeekOfDecember.getMonth(), lastWeekOfDecember.getDate() - 21)) {
        setDiscountPercentage1(20);
        setDiscountPercentage2(30);
        setActiveDiscount1(1);
        setActiveDiscount2(1);
      } else if (currentDate >= new Date(lastWeekOfDecember.getFullYear(), lastWeekOfDecember.getMonth(), lastWeekOfDecember.getDate() - 21) && currentDate <= lastWeekOfDecember) {
        setDiscountPercentage1(10);
        setDiscountPercentage2(20);
        setActiveDiscount1(2);
        setActiveDiscount2(2);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="pre-sale-offer">
      <img src={PreSaleImage} alt="Banner" className="banner" />
      <div className="release-date">
        <span className="digital-text">Full Version Release Date</span>
        <div className="digital-counter">
          <span className="digit">0</span>
          <span className="digit">1</span>
          <span className="digit">0</span>
          <span className="digit">1</span>
          <span className="digit">2</span>
          <span className="digit">0</span>
          <span className="digit">2</span>
          <span className="digit">5</span>
        </div>
      </div>
      <div className="countdown">
        <span className="digital-text">Countdown:</span>
        <div className="digital-counter">
          {String(countdown).padStart(2, '0').split('').map((digit, index) => (
            <span key={index} className="digit">{digit}</span>
          ))}
          <span className="digit">weeks</span>
        </div>
      </div>

      <div className="subscriptions">
        <div className="subscription">
          <h3 className={`subscription-title subscription-title-1 ${activeDiscount1 === 0 ? 'active' : ''}`}>1 Year PRO</h3>
          <p className={`discount ${activeDiscount1 === 0 ? 'active' : ''}`}>Discount: {discountPercentage1}%</p>
          <ul className="discount-list">
            <li className={activeDiscount1 === 0 ? 'active' : ''}>30% (October - Mid November)</li>
            <li className={activeDiscount1 === 1 ? 'active' : ''}>20% (Mid November - Mid December)</li>
            <li className={activeDiscount1 === 2 ? 'active' : ''}>10% (Mid December- End December)</li>
          </ul>
        </div>
        <div className="subscription">
          <h3 className={`subscription-title subscription-title-2 ${activeDiscount2 === 0 ? 'active' : ''}`}>1 Year PREMIUM</h3>
          <p className={`discount ${activeDiscount2 === 0 ? 'active' : ''}`}>Discount: {discountPercentage2}%</p>
          <ul className="discount-list">
            <li className={activeDiscount2 === 0 ? 'active' : ''}>50% (October - Mid November)</li>
            <li className={activeDiscount2 === 1 ? 'active' : ''}>30% (Mid November - Mid December)</li>
            <li className={activeDiscount2 === 2 ? 'active' : ''}>20% (Mid December- End December)</li>
          </ul>
        </div>
      </div>

      <div id="pricing" ref={pricingSectionRef}>
        <StripePricingTable
          publishableKey={stripePublishableKey}
          pricingTableId={stripePricingTableId}
        />
      </div>

      <div className="quick-information">
        <h2 className="section-header">Quick Information</h2>
        <div className="info-section">
          <h3 className="info-heading">Subscription</h3>
          <p>Start date: January 1, 2025 (free beta version until 31 December, 2024)</p>
          <p>End date: December 31, 2025</p>
          <p>Period: 12 months</p>
        </div>
        <div className="info-section">
          <h3 className="info-heading">Cancellation Policy</h3>
          <p>Full refund within 14 days of subscription's start date. No questions asked. No refund possible after this period.</p>
        </div>
        <div className="info-section">
          <h3 className="info-heading">How to Cancel</h3>
          <p>Email us : yourpartner@inter.tennis</p>
        </div>
        <div className="info-section">
          <h3 className="info-heading">Company Details</h3>
          <p>Company Name: Melilot Analytics SA</p>
          <p>Headquarters: Lausanne, Switzerland</p>
        </div>
      </div>
    </div>
  );
};

export default PreSaleOffer;