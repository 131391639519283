import React, { useEffect, useRef } from 'react';
import PreSaleImage from '../../assets/images/pre_sale_banner-3.png'

const StripePricingTable = ({ publishableKey, pricingTableId }) => {
  const pricingTableRef = useRef(null);

  useEffect(() => {
    if (pricingTableRef.current) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      script.onload = () => {
        const pricingTable = pricingTableRef.current;
        pricingTable.innerHTML = `
          <stripe-pricing-table pricing-table-id="${pricingTableId}"
            publishable-key="${publishableKey}">
          </stripe-pricing-table>
        `;
      };
      document.body.appendChild(script);
    }

    return () => {
      const script = document.querySelector('script[src="https://js.stripe.com/v3/pricing-table.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [publishableKey, pricingTableId]);

  return (
    <>
      <img src={PreSaleImage} alt="Banner" className="banner-image" />
      <div ref={pricingTableRef}></div>
    </>
  );};

export default StripePricingTable;