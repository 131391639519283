// GettingStarted.js

import React from 'react';
import './ReferralProgramPageStyles.css';

const ReferralProgramPage = () => {
  return (
    <div className="container">
      <div className="section">
        <h2>Referral Program</h2>
        {/* Step 1 */}
        <div className="panel-container">
          <div className="text-panel panel">
        <h3>Coming Soon</h3>
        <p> 

        You will find more details when we kickstart our referral program. 

      </p>
          </div>
          <div className="image-panel panel">
            <img src="https://stock.inter.tennis/referral%2Freferral_program1.jpg" alt="Step 1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralProgramPage;
