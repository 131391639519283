import React from 'react';
import './PrivacyStatement.css';

const PrivacyStatement = () => {
  return (
    <div className="privacy-statement">
      <h1>Privacy Statement</h1>
      <p>

      </p>
      <p>
        At our platform, we value your privacy and strive to provide transparency regarding how your uploaded videos are treated. This statement outlines the key aspects of public and private video uploads within our mobile app.
      </p>

      <h2>Public Videos:</h2>
      <p>
        By uploading a <strong>public video</strong>, you grant us the following permissions:
      </p>
      <ol>
        <li>The video will be posted in the open arena of our app, accessible to anyone on the internet.</li>
        <li>Statistics generated from public videos will contribute to your user score on your profile.</li>
        <li>We reserve the right to use public videos for training our AI algorithms and for marketing purposes on our social media channels.</li>
      </ol>
      <p>
        <strong>Please note that by uploading a public video, you are consenting to the aforementioned uses and sharing of your content.</strong>
      </p>

      <h2>Private Videos:</h2>
      <p>
        If you choose to upload a <strong>private video</strong>, it will remain accessible only to you within the app. Private videos will not be visible to other users, used for training AI algorithms, or shared on our social media channels. Additionally, statistics from private videos will not contribute to your user score.
      </p>

      <p>
        We encourage users to embrace the spirit of the game by posting publicly, as it facilitates the exchange of ideas and experiences within our community. However, we understand and respect the privacy concerns of those who prefer to keep their content private.
      </p>

      <h2>Comparison:</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Public Videos</th>
            <th>Private Videos</th>
          </tr>
        </thead>
        <tbody>
            <tr>
            <td>Visible to Others</td>
            <td><span className="tick">&#10003;</span></td>
            <td><span className="cross">&#10005;</span></td>
            </tr>
            <tr>
            <td>Contributes to Score</td>
            <td><span className="tick">&#10003;</span></td>
            <td><span className="cross">&#10005;</span></td>
            </tr>
            <tr>
            <td>Used for AI Training</td>
            <td><span className="tick">&#10003;</span></td>
            <td><span className="cross">&#10005;</span></td>
            </tr>
            <tr>
            <td>Used for Marketing</td>
            <td><span className="tick">&#10003;</span></td>
            <td><span className="cross">&#10005;</span></td>
            </tr>
            <tr>
            <td>Privacy</td>
            <td><span className="cross">&#10005;</span></td>
            <td><span className="tick">&#10003;</span></td>
            </tr>
            <tr>
            <td>Community Engagement</td>
            <td><span className="tick">&#10003;</span></td>
            <td><span className="cross">&#10005;</span></td>
            </tr>        
        </tbody>
      </table>

      <p>
        By using our app and uploading videos, you acknowledge and agree to the terms outlined in this Privacy Statement. We encourage you to make an informed decision based on your privacy preferences and desired level of engagement within our community.
      </p>

      <p>
        END
      </p>
    </div>
  );
};

export default PrivacyStatement;