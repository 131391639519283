import React from 'react'

const BlogsPage = () => {
    return (
        <div style={{height:"1000px"}}>
            <h1 className="text-3xl">BlogsPage</h1>
        </div>
    )
}

export default BlogsPage
