import React, { useEffect } from "react";

export default function Eula() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);  return (
    <div
      name="termly-embed"
      data-id="deac8392-0eb4-4ce6-ac82-39130dfe7661"
      data-type="iframe"
    ></div>
  );
}
