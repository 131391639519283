import React from 'react'
import { useTranslation } from 'react-i18next'
import YoutubeEmbed from '../components/common/YoutubeEmbed'
import Logo from '../assets/images/logo_intertennis_white_lowres.png'
import './GettingStartedStyles.css'

const cameraSetupImage = decodeURIComponent(
    'https://stock.inter.tennis/getting_started%2Fimages%2Fcamera_setup.jpg'
)
const courtPositionImage = decodeURIComponent(
    'https://stock.inter.tennis/getting_started%2Fimages%2Fcourt_position_illustration.png'
)
const linesVisibilityImage = decodeURIComponent(
    'https://stock.inter.tennis/getting_started%2Fimages%2Flines_visibility.png'
)
const importVideoImage = decodeURIComponent(
    'https://stock.inter.tennis/getting_started%2Fimages%2Fimport_video.png'
)

const GettingStarted = () => {
    const { t } = useTranslation()

    return (
        <div className="container">
            <div className="getting-started-logo">
                <a
                    href="#"
                    className="flex items-center space-x-3 rtl:space-x-reverse"
                >
                    <img src={Logo} className="h-14" alt="intertennise Logo" />
                </a>
            </div>
            <div className="section">
                <h2>{t('howToUse.setup_instructions')}</h2>

                {/* Youtube Embed */}
                <div className="youtube-embed">
                    <YoutubeEmbed embedId="hvutE3mgeeA?si=P2TvCZ2jCb6C9ncu" />
                </div>
                {/* Step 1 */}
                <div className="panel-container">
                    <div className="text-panel panel">
                        <h3>{t('howToUse.steps.step1.title')}</h3>
                        <ul>
                            <li>
                                <strong>
                                    {t('howToUse.steps.step1.camera')}:
                                </strong>{' '}
                                {t('howToUse.steps.step1.camera_description')}
                            </li>
                            <li>
                                <strong>
                                    {t('howToUse.steps.step1.tripod')}:
                                </strong>{' '}
                                {t('howToUse.steps.step1.tripod_description')}
                            </li>
                            <li>
                                <strong>
                                    {t('howToUse.steps.step1.tennis_gear')}:
                                </strong>{' '}
                                {t(
                                    'howToUse.steps.step1.tennis_gear_description'
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="image-panel panel">
                        <img src={cameraSetupImage} alt="Step 1" />
                    </div>
                </div>

                {/* Step 2 */}
                <div className="panel-container">
                    <div className="image-panel panel max-md:order-1">
                        <img src={courtPositionImage} alt="Step 2" />
                    </div>
                    <div className="text-panel panel">
                        <h3>{t('howToUse.steps.step2.title')}</h3>
                        <ul>
                            <li>
                                <strong>
                                    {t('howToUse.steps.step2.placement')}:
                                </strong>{' '}
                                {t(
                                    'howToUse.steps.step2.placement_description'
                                )}
                            </li>
                            <li>
                                <strong>
                                    {t('howToUse.steps.step2.important_lines')}:
                                </strong>{' '}
                                {t(
                                    'howToUse.steps.step2.important_lines_description'
                                )}
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Step 3 */}
                <div className="panel-container">
                    <div className="text-panel panel">
                        <h3>{t('howToUse.steps.step3.title')}</h3>
                        <ul>
                            <li>
                                <strong>
                                    {t('howToUse.steps.step3.stability')}:
                                </strong>{' '}
                                {t(
                                    'howToUse.steps.step3.stability_description'
                                )}
                            </li>
                            <li>
                                <strong>
                                    {t('howToUse.steps.step3.focus')}:
                                </strong>{' '}
                                {t('howToUse.steps.step3.focus_description')}
                            </li>
                            <li>
                                <strong>
                                    {t('howToUse.steps.step3.single_player')}:
                                </strong>{' '}
                                {t(
                                    'howToUse.steps.step3.single_player_description'
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="image-panel panel">
                        <img src={linesVisibilityImage} alt="Step 3" />
                    </div>
                </div>

                {/* Step 4 */}
                <div className="panel-container">
                    <div className="image-panel panel max-md:order-1">
                        <img src={importVideoImage} alt="Step 4" />
                    </div>
                    <div className="text-panel panel">
                        <h3>{t('howToUse.steps.step4.title')}</h3>
                        <ul>
                            <li>{t('howToUse.steps.step4.description')}</li>
                        </ul>
                    </div>
                </div>

                {/* Where to find Tripod? */}
                <div className="panel-container">
                    <div className="text-panel panel">
                        <h3>{t('howToUse.tripod_tips.title')}</h3>
                        <ul>
                            <li>{t('howToUse.tripod_tips.description')}</li>
                        </ul>
                    </div>
                </div>

                {/* Disclaimer */}
                <div className="panel-container">
                    <div className="text-panel panel">
                        <h3>{t('howToUse.disclaimer.title')}</h3>
                        <p>{t('howToUse.disclaimer.description')}</p>
                    </div>
                </div>

                {/* Youtube Embed */}
                <div className="youtube-embed">
                    <YoutubeEmbed embedId="zT-fxOdH5rs" />
                </div>
            </div>
        </div>
    )
}

export default GettingStarted
