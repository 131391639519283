import React from 'react'
import { components } from 'react-select'

const { Option } = components
const IconOptions = (props) => (
    <Option {...props}>
        <div className="flex gap-2 items-center">
            <img
                src={props.data.icon}
                style={{ width: 20 }}
                alt={props.data.label}
            />
            <p className="text-xs">{props.data.label}</p>
        </div>
    </Option>
)
export default IconOptions
