// GettingStarted.js

import React from 'react';
import './FeaturesStyles.css';

const Features = () => {
  return (
    <div className="container">
      <div className="section">
        <h2>App Features</h2>
        {/* Step 1 */}
        <div className="panel-container">
          <div className="text-panel panel">
            <h3>Player Follow Zoom</h3>
            <li>Zoomed window tracking the player for better view of your shots. </li>
            <li>Automatic slow-motion during the shot.</li>
          </div>
          <div className="image-panel panel">
            <img src="https://stock.inter.tennis/features%2Fimages%2Fplayer_track_zoom.png" alt="Step 1" />
          </div>
        </div>
        {/* Step 2 */}
        <div className="panel-container">
          <div className="image-panel panel">
            <img src="https://stock.inter.tennis/features%2Fimages%2Fcourt_widget.png" alt="Step 2" />
          </div>
        <div className="text-panel panel">
        <h3>Court Tracking</h3>
          <li>Player's position on the court. </li>
          <li>Position of ball bounce and ball hit on the court. </li>
          </div>
        </div>
        {/* Step 3 */}
        <div className="panel-container">
          <div className="text-panel panel">
        <h3>Highlights Shot by Shot</h3>
            <li>Easily jump to shot clips in the video. Watch only forehands, only backhands. </li>

            <li>Filter by any combination of shot type</li>
          </div>
          <div className="image-panel panel">
            <img src="https://stock.inter.tennis/features%2Fimages%2Fhighlights.png" alt="Step 3" />
          </div>
        </div>
        {/* Step 4 */}
        <div className="panel-container">
          <div className="image-panel panel">
            <img src="https://stock.inter.tennis/features%2Fimages%2Fshot_analytics.png" alt="Step 2" />
          </div>
          <div className="text-panel panel">
        <h3>Shot Analytics</h3>
          <li>Ball Speed, sprint distance and other analytics generated shot by shot </li>
          </div>
        </div>
        {/* Step 3 */}
        <div className="panel-container">
          <div className="text-panel panel">
        <h3>Shot Counter</h3>
            <li>Check the number of forehands, backhands, serves and volleys hit in the video </li>

          </div>
          <div className="image-panel panel">
            <img src="https://stock.inter.tennis/features%2Fimages%2Fshot_counter.png" alt="Step 3" />
          </div>
        </div>

      </div>
    </div>
  );
};

export default Features;
